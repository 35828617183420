import React from "react";
import theme from "theme";
import { Theme, Text, Box, Section, Button, Image } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"service"} />
		<Helmet>
			<title>
				Hizmetlerimiz | Sunset Ridge Golf Kulübü
			</title>
			<meta name={"description"} content={"Golf Deneyiminizi Geliştirin - Hizmet Yelpazemizi Keşfedin\n"} />
			<meta property={"og:title"} content={"Hizmetlerimiz | Sunset Ridge Golf Kulübü"} />
			<meta property={"og:description"} content={"Golf Deneyiminizi Geliştirin - Hizmet Yelpazemizi Keşfedin\n"} />
			<meta property={"og:image"} content={"https://bosphorusbeauty.com/img/1.jpg"} />
			<link rel={"shortcut icon"} href={"https://bosphorusbeauty.com/img/5950392.png"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://bosphorusbeauty.com/img/5950392.png"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://bosphorusbeauty.com/img/5950392.png"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://bosphorusbeauty.com/img/5950392.png"} />
			<link rel={"apple-touch-startup-image"} href={"https://bosphorusbeauty.com/img/5950392.png"} />
			<meta name={"msapplication-TileImage"} content={"https://bosphorusbeauty.com/img/5950392.png"} />
		</Helmet>
		<Components.Header />
		<Section background="--color-primary" padding="60px 0" sm-padding="40px 0">
			<Box
				margin="-16px -16px -16px -16px"
				padding="0px 0px 0px 0px"
				display="flex"
				width=" "
				flex-wrap="wrap"
			>
				<Box padding="16px 16px 16px 16px" display="flex" width="50%" lg-width="100%">
					<Box
						padding="98px 64px 98px 64px"
						display="flex"
						flex-direction="column"
						background="--color-light"
						color="--dark"
					>
						<Text
							as="h1"
							margin="12px 0"
							font="--base"
							color="--grey"
							letter-spacing="1px"
							text-transform="uppercase"
						>
							Önemli Hizmet Noktaları
						</Text>
						<Text as="p" margin="12px 0" font="--lead" md-font="--headline3">
							Sunset Ridge Golf Kulübü, golf yolculuğunuzu geliştirmek ve pitoresk sahalarımızda unutulmaz anlar yaratmak için tasarlanmış çeşitli hizmetler sunmaktadır. Golf paketlerinden seçkin yemek seçeneklerine kadar, kendimizi eksiksiz bir golf deneyimi sunmaya adadık.
						</Text>
					</Box>
				</Box>
				<Box display="flex" width="50%" flex-wrap="wrap" lg-width="100%">
					<Box
						padding="16px 16px 16px 16px"
						display="flex"
						width="100%"
						lg-width="33.3333%"
						md-width="100%"
						sm-width="100%"
					>
						<Box
							padding="0px 0px 624px 0px"
							display="flex"
							flex-direction="column"
							background="url(https://bosphorusbeauty.com/img/4.jpg) center/cover"
							width="100%"
							lg-padding="0px 0px 248px 0px"
							sm-padding="0px 0px 380px 0px"
						/>
					</Box>
					<Box
						padding="16px 16px 16px 16px"
						display="flex"
						width="50%"
						lg-width="33.3333%"
						md-width="50%"
						sm-width="100%"
					>
						<Box
							padding="0px 0px 296px 0px"
							display="flex"
							flex-direction="column"
							background="url(https://bosphorusbeauty.com/img/5.jpg) center/cover"
							width="100%"
							lg-padding="0px 0px 248px 0px"
							sm-padding="0px 0px 380px 0px"
						/>
					</Box>
					<Box
						padding="16px 16px 16px 16px"
						display="flex"
						width="50%"
						lg-width="33.3333%"
						md-width="50%"
						sm-width="100%"
					>
						<Box
							padding="0px 0px 296px 0px"
							display="flex"
							flex-direction="column"
							background="url(https://bosphorusbeauty.com/img/6.jpg) center/cover"
							width="100%"
							lg-padding="0px 0px 248px 0px"
							sm-padding="0px 0px 380px 0px"
						/>
					</Box>
				</Box>
			</Box>
		</Section>
		<Section padding="80px 0 90px 0" quarkly-title="Headline-4">
			<Override slot="SectionContent" sm-min-width="280px" />
			<Box min-width="100px" min-height="100px" display="flex" lg-flex-direction="column">
				<Text
					margin="0px 0px 0px 0px"
					width="50%"
					font="normal 500 52px/1.2 --fontFamily-serifTimes"
					padding="0px 50px 0px 0px"
					lg-width="100%"
					lg-margin="0px 0px 50px 0px"
					md-padding="0px 0 0px 0px"
					md-font="normal 500 42px/1.2 --fontFamily-serifTimes"
					sm-margin="0px 0px 35px 0px"
				>
					Golf Kaçamakları
				</Text>
				<Box
					min-width="100px"
					min-height="100px"
					width="50%"
					display="flex"
					flex-direction="column"
					justify-content="center"
					lg-width="100%"
				>
					<Text margin="0px 0px 20px 0px" font="--lead" color="#727a82" sm-margin="0px 0px 25px 0px">
						- Şampiyona Sahaları: Her beceri seviyesinden golfçüye hitap eden ve zorlu ancak ödüllendirici turlar sunan, titizlikle tasarlanmış sahalarımızın keyfini çıkarın.
						<br />
						<br />
						- Alacakaranlık Başlama Saatleri: Her deliğin çarpıcı bir gün batımı fonunda yer aldığı akşam golfünün büyüsünü yaşayın.
						<br />
						<br />
						- Profesyonel Talimat: Deneyimli eğitmenlerimizden alacağınız kişiselleştirilmiş golf dersleriyle oyununuzu geliştirin.
					</Text>
				</Box>
			</Box>
		</Section>
		<Section padding="100px 0 100px 0" quarkly-title="FAQ-10">
			<Box min-width="100px" min-height="100px" display="flex" lg-flex-direction="column">
				<Box min-width="100px" min-height="100px">
					<Text margin="0px 0px 15px 0px" font="normal 600 36px/1.2 --fontFamily-sans" color="--darkL1">
						Hizmetlerimiz
					</Text>
					<Text
						margin="0px 0px 70px 0px"
						font="normal 300 20px/1.5 --fontFamily-sansHelvetica"
						color="#555a5f"
						sm-margin="0px 0px 50px 0px"
						lg-margin="0px 0px 50px 0px"
					>
						Golf Deneyiminizi Geliştirin - Hizmet Yelpazemizi Keşfedin
					</Text>
				</Box>
				<Box
					min-width="100px"
					min-height="100px"
					display="block"
					grid-template-columns="repeat(2, 1fr)"
					grid-gap="50px 50px"
					md-grid-template-columns="1fr"
					sm-grid-gap="35px 0"
					padding="0px 0px 0px 70px"
					lg-padding="0px 0px 0px 0"
				>
					<Box min-width="100px" min-height="100px" margin="0px 0px 35px 0px">
						<Text margin="0px 0px 12px 0px" font="normal 500 22px/1.2 --fontFamily-sans" color="--darkL1">
							Üyelik Paketleri
						</Text>
						<Text margin="0px 0px 0px 0px" font="normal 300 16px/1.5 --fontFamily-sansHelvetica" color="#555a5f">
							- Ayrıcalıklı Avantajlar: Sunset Ridge'e üye olun ve öncelikli tee zamanları ve özel etkinlikler de dahil olmak üzere bir dizi ayrıcalığa erişim kazanın.
							<br />
							- Esnek Seçenekler: Kişisel tercihlerinize ve oyun oynama sıklığınıza uyacak şekilde tasarlanmış çeşitli üyelik paketlerinden birini seçin.
						</Text>
					</Box>
					<Box min-width="100px" min-height="100px" margin="0px 0px 35px 0px">
						<Text margin="0px 0px 12px 0px" font="normal 500 22px/1.2 --fontFamily-sans" color="--darkL1">
							Yemek Lezzetleri
						</Text>
						<Text margin="0px 0px 0px 0px" font="normal 300 16px/1.5 --fontFamily-sansHelvetica" color="#555a5f">
							- Kulüp Evi Mutfağı: Kulüp binamızın davetkâr ortamında gurme yemeklerin ve serinletici içeceklerin tadını çıkarın.
							<br />
							- 19. Delik Buluşmaları: Turunuzdan sonra golfçü arkadaşlarınızla dinlenin, hikayelerinizi paylaşın ve kulüp binamızın dostluğunun tadını çıkarın.
						</Text>
					</Box>
					<Box min-width="100px" min-height="100px" margin="0px 0px 35px 0px">
						<Text margin="0px 0px 12px 0px" font="normal 500 22px/1.2 --fontFamily-sans" color="--darkL1">
							Turnuvalar ve Etkinlikler
						</Text>
						<Text margin="0px 0px 0px 0px" font="normal 300 16px/1.5 --fontFamily-sansHelvetica" color="#555a5f">
							- Rekabet edin ve Bağlantı Kurun: Becerilerinizi sergileyebileceğiniz ve yeni arkadaşlıklar kurabileceğiniz golf turnuvalarımıza ve etkinliklerimize katılın.
							<br />
							- Özel Etkinlikler: İster kurumsal bir gezi ister sosyal bir toplantı olsun, kendi golf etkinliğinizi planlayın ve unutulmaz kılmamıza izin verin.
						</Text>
					</Box>
					<Box min-width="100px" min-height="100px">
						<Text margin="0px 0px 12px 0px" font="normal 500 22px/1.2 --fontFamily-sans" color="--darkL1">
							Profesyonel Dükkanın Temelleri
						</Text>
						<Text margin="0px 0px 0px 0px" font="normal 300 16px/1.5 --fontFamily-sansHelvetica" color="#555a5f">
							- En Kaliteli Ekipmanlar: Oyununuzu geliştirecek çeşitli golf ekipmanları, giysiler ve aksesuarlar için Pro Shop'umuzu keşfedin.
							<br />
							- Uzman Tavsiyesi: Güler yüzlü personelimiz, bilinçli seçimler yapmanıza yardımcı olmak için öneriler ve tavsiyeler sağlayabilir.
						</Text>
					</Box>
				</Box>
			</Box>
		</Section>
		<Section padding="90px 0 100px 0" quarkly-title="List-5">
			<Box
				min-width="100px"
				min-height="100px"
				display="grid"
				grid-template-columns="repeat(2, 1fr)"
				grid-gap="56px 34px"
				lg-grid-template-columns="repeat(2, 1fr)"
				sm-grid-template-columns="1fr"
				sm-grid-gap="36px 0"
				md-grid-gap="50px 0"
				margin="0px 130px 0px 130px"
				lg-margin="0px 0 0px 0"
				md-grid-template-columns="1fr"
			>
				<Box
					min-width="10px"
					min-height="10px"
					display="flex"
					flex-direction="column"
					padding="0px 50px 0px 0px"
					align-items="flex-start"
					md-padding="0px 0 0px 0px"
				>
					<Text margin="0px 0px 25px 0px" font="normal 500 28px/1.2 --fontFamily-sans" md-margin="0px 0px 20px 0px">
						Bize Ulaşın
					</Text>
					<Text margin="0px 0px 35px 0px" font="normal 300 18px/1.5 --fontFamily-sansHelvetica" color="#474a4d" md-margin="0px 0px 25px 0px">
						Hizmetlerimiz hakkında daha fazla ayrıntı, üyelik bilgileri veya Sunset Ridge'deki bir sonraki golf maceranızı planlamak için lütfen bizimle iletişime geçmekten çekinmeyin. Kendini işine adamış ekibimiz, ömür boyu sürecek golf anıları yaratmanızda size yardımcı olmak için burada.
					</Text>
					<Button
						background="#0438cd"
						href="/contacts"
						type="link"
						text-decoration-line="initial"
						padding="11px 28px 11px 28px"
						border-radius="25px"
					>
						Kişiler
					</Button>
				</Box>
				<Image src="https://bosphorusbeauty.com/img/7.jpg" display="block" width="100%" md-order="-1" />
			</Box>
		</Section>
		<Components.Footer />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65323428c5fd0f0020b15081"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});